/* ./resources/css/app.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.min-h-screen {
    min-height: -webkit-fill-available;
}

.react-datepicker {
    @apply relative bg-white
}

.react-datepicker__navigation-icon {
    @apply sr-only
}

.react-datepicker__input-container input {
    @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded-md shadow-sm focus:ring-accent
}

.react-datepicker__tab-loop {
    @apply absolute
}

.react-datepicker__tab-loop .react-datepicker-popper {
    @apply absolute top-1 z-10 left-0 w-auto text-sm bg-white px-3 rounded-md ring-1 ring-black ring-opacity-5 shadow-lg !important
}

.react-datepicker__month-container {
    @apply flex flex-col
}

.react-datepicker__month {
    @apply flex flex-col
}

.react-datepicker__current-month {
    @apply text-center text-lg font-semibold text-gray-800 pb-2
}

.react-datepicker__week {
    @apply flex justify-around
}

.react-datepicker__day-names {
    @apply flex justify-around text-gray-600 font-medium text-center text-xs
}

.react-datepicker__day-name {
    @apply border border-transparent w-8 h-8 px-5 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
    @apply absolute top-0
}

.react-datepicker__navigation--previous {
    @apply absolute left-0 w-10 h-8 shadow-sm text-gray-600 border border-gray-300 rounded transition flex items-center justify-center hover:bg-gray-50
}

.react-datepicker__navigation--previous::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>');
    @apply w-6 h-6 flex-shrink-0
}

.react-datepicker__navigation--next::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>');
    @apply w-6 h-6 flex-shrink-0
}

.react-datepicker__navigation--next {
    @apply absolute right-0 w-10 h-8 shadow-sm text-gray-600 border border-gray-300 rounded transition flex items-center justify-center hover:bg-gray-50
}

.react-datepicker__day {
    @apply focus:outline-none w-8 h-8 p-5 text-sm border border-transparent rounded-full leading-loose flex items-center justify-center font-medium hover:border-gray-800
}

.react-datepicker__day--keyboard-selected {
    @apply border-accent
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed text-gray-300 border-transparent hover:border-transparent line-through font-normal bg-transparent
}

.react-datepicker__day--outside-month {
    @apply pointer-events-none opacity-0
}

.react-datepicker__day--in-range {
    @apply bg-gray-200
}

.react-datepicker__day--in-selecting-range {
    @apply bg-blue-200
}

.react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selected {
    @apply bg-accent text-on-accent
}

.react-datepicker__day--range-start {
    @apply bg-accent text-on-accent hover:text-gray-700 hover:bg-white
}

.react-datepicker__day--range-end {
    @apply bg-accent text-on-accent hover:text-gray-700 hover:bg-white
}

.react-phone-input .PhoneInput {
    @apply border border-gray-300 rounded-md shadow-sm overflow-hidden
}

.react-phone-input .PhoneInput .PhoneInputCountry {
    @apply p-2 mr-0
}

.react-phone-input[data-disabled] .PhoneInput .PhoneInputCountry {
    @apply bg-gray-100
}

.react-phone-input .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
    @apply rounded-sm
}

.react-phone-input .PhoneInput input.PhoneInputInput {
    @apply border-0 sm:text-sm focus:outline-none focus:ring-0
}

.react-phone-input .PhoneInput input.PhoneInputInput:disabled {
    @apply bg-gray-100 text-gray-700 cursor-not-allowed
}

.react-phone-input .PhoneInput.PhoneInput--focus {
    @apply ring-accent ring-1 border-accent
}

.react-phone-input .PhoneInput.PhoneInput--focus .PhoneInputCountry select:focus {
    @apply border-accent ring-accent
}

.react-daterangepicker .react-datepicker__month {
    @apply space-y-2
}

.react-daterangepicker .react-datepicker__day-name {
    @apply rounded-md
}

.react-daterangepicker .react-datepicker__day {
    @apply flex-1 aspect-square h-auto leading-snug rounded-lg p-0 text-base
}

.react-daterangepicker .react-datepicker__day.react-datepicker__day--in-range {
    @apply rounded-none
}

.react-daterangepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start {
    @apply rounded-l-lg
}

.react-daterangepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
    @apply rounded-r-lg
}

.react-daterangepicker .react-datepicker__day .price_from {
    @apply text-[11px] leading-none
}

.react-daterangepicker .react-datepicker__day--disabled .price_from {
    @apply opacity-0
}

.react-daterangepicker .react-datepicker__navigation--previous {
    @apply absolute left-0 p-3 h-auto w-auto flex-1 aspect-square shadow-sm text-gray-600 border border-gray-200 rounded transition flex items-center justify-center hover:bg-gray-50
}

.react-daterangepicker .react-datepicker__navigation--next {
    @apply absolute right-0 p-3 h-auto w-auto flex-1 aspect-square shadow-sm text-gray-600 border border-gray-200 rounded transition flex items-center justify-center hover:bg-gray-50
}

.react-daterangepicker .react-datepicker__current-month {
    @apply pb-[20px] pt-[13px]
}

.adyen-checkout__applepay__button {
    @apply !w-full
}

.adyen-checkout-form-instruction {
    @apply !hidden
}

.adyen-checkout__input {
    @apply !border-gray-300
}